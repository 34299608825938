@import "~antd/dist/antd.css";

body {
  margin: 0;
  width: 100%;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-breadcrumb {
  width: 100%;
  display: flex;
  font-size: 16px;
  color: black;
  box-sizing: border-box;
  padding-top: 20px;
  margin-left: 10px;

  > span {
    display: flex;
  }

  .ant-breadcrumb-separator {
    color: black;
  }
}

@media screen and (max-width: 792px) {
  .ant-breadcrumb {
    display: none;
  }
}

.previous-route {
  text-decoration: underline;
  text-decoration-color: #e00000;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  ::-webkit-scrollbar {
    width: 0 !important;
    margin-left: 0 !important;
  }
}
